// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-wrapper{
    margin-top: 40px;
}

.log-wrapper{
    min-height: 63vh;
    height: 63vh;
    overflow-y: scroll;
    margin: 0 4vw 2vw 4vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1vh 2vw;
}

.log-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/LoggerUI/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,iDAAiD;IACjD,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".main-wrapper{\n    margin-top: 40px;\n}\n\n.log-wrapper{\n    min-height: 63vh;\n    height: 63vh;\n    overflow-y: scroll;\n    margin: 0 4vw 2vw 4vw;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    padding: 1vh 2vw;\n}\n\n.log-loader{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
