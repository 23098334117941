// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-wrapper{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 80%; 
    margin-top: 100px;
    height: 68vh; 
    max-height: 686px;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Rovers/index.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".table-wrapper{\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    width: 80%; \n    margin-top: 100px;\n    height: 68vh; \n    max-height: 686px;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
