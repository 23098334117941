import "./index.css";
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useAppDispatch } from "../../context/CustomHooks";
import { fetchLogStream, logsSelector } from "../../services/RoverLogs/RoverLogsSlice";
import { loadingSelector } from "../../services/ThunkMiddleware/ThunkMiddlewareSlice";
import { filterResultsSelector, transformRoverDataToString, fetchFilterResults, paginationSelector, hasMoreSelector, setPage } from "../../services/Filter/FilterSlice";
import { useSelector } from "react-redux";
import MessageView from "../MessageView";
import { ILoggerUIProps } from "../../commons/interfaces/ILoggerUIProps";
import Log from "../Log";

export default function LoggerUI({ viewMode, filterRequest } : ILoggerUIProps) {
    const dispatch = useAppDispatch();
    const logs = useSelector(logsSelector);
    const loading = useSelector(loadingSelector)
    const filteredLogs = useSelector(filterResultsSelector);
    const { page } = useSelector(paginationSelector);
    const hasMore = useSelector(hasMoreSelector);

    const [streamData, setStreamData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const loaderRef = useRef(null);

    useEffect(() => {
        if (logs === null) {
            dispatch(fetchLogStream());
        } else {
            setStreamData(logs);
        }
    }, [dispatch, logs]);

    useEffect(() => {
        setFilteredData(filteredLogs);
    }, [filteredLogs]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && viewMode === 'filtered') {
                dispatch(setPage(page + 1));
            }
        }, { threshold: 1.0 });
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => observer && loaderRef.current && observer.disconnect();
    }, [dispatch, page, hasMore, viewMode]);

    useEffect(() => {
        if (viewMode === 'filtered') {
            const strFilterRequest = transformRoverDataToString(filterRequest);
            dispatch(fetchFilterResults(strFilterRequest));
        }
    }, [dispatch, page, viewMode]);

    return (
        <Box className="main-wrapper">
            <Typography variant="h4" style={{ marginLeft: '4vw' }}>
                {viewMode === 'stream' ? 'Rover Logs (latest)' : 'Rover Logs (filtered)'}
            </Typography>
            <Box className="log-wrapper">
            {loading && Object.values(loading).includes(true) ? (
                <div className="log-loader">
                    <CircularProgress />
                </div>
                ) : (
                    <>
                        {(viewMode === 'stream' ? streamData : filteredData).length > 0 ? (
                            <>
                                <Typography variant="body1">Scroll down to view more logs</Typography>
                                {(viewMode === 'stream' ? streamData.slice(0).reverse() : filteredData).map((log, i) => (
                                    <Log key={i} log={log} />
                                ))}
                            </>
                    ) : (
                        <MessageView content={viewMode === 'stream' ? "There are no logs at the moment" : "No logs match this filter"} />
                    )}
                    {viewMode !== 'stream' && <div ref={loaderRef} style={{ height: "20px" }}></div>}
                </>
                )}
            </Box>

        </Box>
    );
}   




