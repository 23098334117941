// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-body {
    max-height: 681px;
    overflow-y: auto;
}

.table-row {
    height: 52px;
    box-sizing: border-box;
}

.table-cell {
    text-align: center !important;
}

.table-header {
    font-weight: bold !important;
    font-size: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MarsTable/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".table-body {\n    max-height: 681px;\n    overflow-y: auto;\n}\n\n.table-row {\n    height: 52px;\n    box-sizing: border-box;\n}\n\n.table-cell {\n    text-align: center !important;\n}\n\n.table-header {\n    font-weight: bold !important;\n    font-size: 1rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
